import { Environment } from '@interfaces/environment';

export const environment: Environment = {
  production: false,
  ENV_NAME: 'grey',
  AUTH0_DOMAIN: 'auth0.tiime.fr',
  AUTH0_CLIENT_ID: 'CoQ2r9BNBNgsHCdghrScp7cZ5nxaudYc',
  AUTH0_AUDIENCE: 'https://chronos/',
  API_URL: 'https://chronos-review-grey.preprod.tiime.tech',
  APPS_URL: 'https://apps.chronos-review-grey.preprod.tiime.tech',
  HUB_URL: 'https://api.preprod.tiime.tech/provider/chronos',
  HUB_VERSION: '1.0',
  HUB_WHITE_DOMAINS: ['api.preprod.tiime.tech/provider/chronos'],
  SENTRY_DSN: null,
  HELP_SCOUT_BEACON_ID: '730efaec-5158-4118-b4df-0b6c0b396ba2',
  CARE_FORM_URL: 'https://tiimeforce--preprod.sandbox.my.salesforce-sites.com/tiimecare/VF06_CareCaseForm_Web',
  FIREBASE: {
    apiKey: 'AIzaSyAS8nCWzq2X_mZK0C4axxBIxZPi8aGPCRI',
    authDomain: 'expertpreprod.firebaseapp.com',
    projectId: 'expertpreprod',
    storageBucket: 'expertpreprod.appspot.com',
    messagingSenderId: '769934369164',
    appId: '1:769934369164:web:66367f2405ff825f4736a9',
    vapidKey: 'BHYuaQCMaWEOoe_azQpdtuJinV3mu742mdUXEsR0wFxipeA6WjTAf_ddDF7Jk6u_PCAxWlBXwlpIh7WH--SBUOg'
  }
};
